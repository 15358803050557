@tailwind base;
@tailwind components;
@tailwind utilities;
.ag-row-first .grid-cell {
    border-top: 1px solid #000;
    padding: '10px'
}

.btn-indigo {
    @apply flex   justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600;
  }


.error-text {
    @apply mt-2 text-sm text-red-600
}
